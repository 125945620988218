//import
import {Component, Fragment} from 'react'

class Projects extends Component {

	//ctor
	constructor(props) {
		super(props);
		this.state = {};
	}
	//fetch content from server when component mounts
	componentDidMount() {
		fetch("https://dev.marius-unger.de/projects.json")
		.then(res => res.json())
		.then(data => {
			this.setState(data);
		});
	}


	/* RENDER */
	//returns project description paragraphs
	buildDescription(desc, id) {
		if(desc === undefined) {
			return null;
		}
		else {
			return desc.map((desc, index) => {
				return(<p key={"p-" + id + "-" + index}>{desc}</p>);
			});
		}
	}
	//returns a project tile's jsx to buildProjectTree()
	buildProject(id, title, img, desc) {
		const animOffset = 0.5 * id; 
		//initiate spacers
		const spacer = <div className="col-lg-2 spacer" key={"spacer" + id}></div>;
		let spacerBeginning = null, spacerEnd = null;
		//will be declared to a css animation name
		let anim;
		//path to dynamically import img
		const imgPath = require(`${img}`).default;

		//change spacing for every project tile
		if(id % 2 === 0) {
			spacerEnd = spacer;
			anim = "fade-in-left";
		}
		else {
			spacerBeginning = spacer
			anim = "fade-in-right";
		}

		//return project jsx
		return(
			<Fragment key={"frag-" + id}>
			<div className={"row fade-anim " + anim} style={{animationDelay: animOffset + 's'}}>
			{spacerBeginning}
			<div className="col-lg-10 project" key={"project-" + id}>
			<div className="project-img" key={"project_img-" + id}>
			<img src={imgPath} alt={"image for project " + title} />
			</div>
			<div className="project-content" key={"content-" + id}>
			<h2 className="project-title">
			{title}
			</h2>
			<div className="project-desc" key={"desc-" + id}>
			{this.buildDescription(desc, id)}
			</div>
			</div>
			</div>
			{spacerEnd}
			</div>
			</Fragment>
			);

	}
	//passes jsx of project information tiles to render funtion
	buildProjectTree() {
		if(this.state.projects !== undefined) {
			return this.state.projects.map((project, id) => {
				return this.buildProject(id, project.title, project.img, project.desc);
			});
		}
	}

	render() {
		return(
			<div id="projects" className="row">
			<div className="col-12">
			{this.buildProjectTree()}
			</div>
			</div>
			);
	}
}

export default Projects;