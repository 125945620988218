//imports
import {Component, Fragment, createRef} from 'react'

//stylesheets
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './App.css';

//components
import {Home, Projects, About, Contact} from './components'

//main component
class App extends Component {

  /* COMPONENT METHODS */
  //ctor
  constructor(props) {
    super(props);
    this.state = {
      view: {
        Home: true,
        Projects: false,
        About: false,
        Contact: false
      },
      canvas: {
        width: 0,
        height: 0
      },
      isMobile: false,
      mobileMenuView: false
    }

    //binded functions
    this.showState = this.showState.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleHomeState = this.handleHomeState.bind(this);
    this.draw = this.draw.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.switchCursorHoverEffect = this.switchCursorHoverEffect.bind(this);
    this.resize = this.resize.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);

    //add event listener and start drawing on svg
    this.cursor = createRef();
    this.wrapperRef = createRef();
    this.canvasRef = createRef();
  }

  /* on component mount/update/unmount */
  componentDidMount() {
    this.updateDimensions();
    //add event listener to update canvas dimensions
    window.addEventListener('resize', this.updateDimensions);
    //checks for mobile
    window.addEventListener("resize", this.resize);
    this.resize();
  }
  componentDidUpdate() {
    //event listeners for cursor
    const hoverLink = document.querySelectorAll("a, button");
    hoverLink.forEach(element => {
      element.addEventListener("mouseenter", this.switchCursorHoverEffect);
      element.addEventListener("mouseleave", this.switchCursorHoverEffect);
    });
  }
  componentWillUnmount() {
    //remove event listener to update svg dimensions
    window.removeEventListener('resize', this.updateDimensions);
    //removes event listener checking mobile
    window.removeEventListener("resize", this.resize.bind(this));
    //remove event listeners for cursor
    const hoverLink = document.querySelectorAll("a, button");
    hoverLink.forEach(element => {
      element.removeEventListener("mouseenter", this.switchCursorHoverEffect);
      element.removeEventListener("mouseleave", this.switchCursorHoverEffect);
    });
  }


  /* CANVAS */
  getCanvasContext(noCanvas = false) {
    const canvas = this.canvasRef.current, context = canvas.getContext('2d');
    if(noCanvas)
      return context;
    else
      return {canvas, context};
  }
  draw(event) {
    //update cursor first
    this.updateCursor(event.clientX, event.clientY);
    //get mouse position and save coordinates
    const {canvas, context} = this.getCanvasContext();
    const coords = this.getMousePos(canvas, event);
    const x = coords.x, y = coords.y;
    
    context.fillStyle = "rgba(255, 255, 255, 0.008)";
    context.beginPath();
    context.arc(x, y, 12, 0, 2 * Math.PI);
    context.fill();
  }
  //changes canvas dimensions in component state
  updateDimensions() {
    //save canvas dimensions to state which will update canvas dimensions automatically
    this.setState({ 
      canvas: {
        width: this.canvasRef.current.clientWidth,
        height: this.canvasRef.current.clientHeight
      }
    });
  }


  /* EVENT METHODS */
  //handles user navigating
  handleViewChange(event) {
    //get clicked view val
    const view = event.target.title;
    //remove clicked view from arr
    const views = ["Home", "Projects", "About", "Contact"].filter(stay => stay !== view);
    
    //get copy of state and set view values
    let newState = Object.assign({}, this.state);
    newState.view[event.target.title] = true;
    views.map(view => newState.view[view] = false);

    //set state with updated nav variables
    this.setState(newState);
  }
  //returns the clients mouse cursor position if in canvas
  getMousePos(canvas, evt) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }
  //toggles hover class for cursor
  switchCursorHoverEffect() {
    let cursor = this.cursor.current;
    const hovering = cursor.classList.contains("cursor-hover");
    if(hovering) 
      cursor.classList.remove("cursor-hover");
    else
      cursor.classList.add("cursor-hover");
  }
  //sets a state indicating if client is using a mobile device
  resize() {
    this.setState( {
      isMobile: window.innerWidth <= 780
    });
  }
  //toggles mobile menu
  toggleMobileMenu() {
    this.setState({
      mobileMenuView: !this.state.mobileMenuView
    });
  }


  /* GENERAL */
  //prints state
  showState() {
    console.log(this.state);
  }
  handleHomeState(homeState) {
    this.setState({
      home: homeState
    });
  }
  //let the custom cursor follow the mouse movement
  updateCursor(x, y) {
      this.cursor.current.style.top = (y - this.cursor.current.clientHeight / 2);
      this.cursor.current.style.left = (x - this.cursor.current.clientWidth / 2);
  }


  /* RENDER */
  //returns the navigation menu as jsx frag
  buildNavigation() {
    let navigationTree;

    //tree for mobile menu
    const mobileTree =
      <Fragment>
        <div className="home-button">
          <button className="rect" title="Home" onClick={this.handleViewChange}>
          </button>
        </div>
        <div className="mobile-nav-button-cont">
          <button className="mobile-nav-button" onClick={this.toggleMobileMenu} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </button>
        </div>
      </Fragment>;
    //tree for desktop menu
    const desktopTree =
      <Fragment>
        <div className="home-button">
          <button className="rect" title="Home" onClick={this.handleViewChange}>
          </button>
        </div>
        <div id="nav">
          {this.getNavLinks()}
        </div>
      </Fragment>;

    if(this.state.isMobile)
      //mobile
      navigationTree = mobileTree;
    else 
      //desktop
      navigationTree = desktopTree;

    return navigationTree;
  }
  //returns mobile menu as jsx frag
  buildMobileNav() {
    if(this.state.isMobile && this.state.mobileMenuView) {
      return(
        <Fragment>
          <div id="mobile-nav">
            <div className="close-menu" onClick={this.toggleMobileMenu}>
            </div>
            <div id="nav">
              {this.getNavLinks()}
            </div>
          </div>
        </Fragment>
        );
    }
    else
      return null;
  }
  //returns navigation links
  getNavLinks() {
    let navLinkTree;
    if(this.state.isMobile) {
      /* custom linktree with more onclick events:
       * + mobile menu will be toggled on click 
       * + cursor hover effect will be toggled due to mouseleave event not being fired (because element will be removed before mouse leave)
       */
      navLinkTree = 
      <Fragment>
        <button className="nav-button" title="Projects" onClick={ (e) => {this.handleViewChange(e); this.toggleMobileMenu(); this.switchCursorHoverEffect();} }>
          Projects
        </button>
        <button className="nav-button" title="About" onClick={ (e) => {this.handleViewChange(e); this.toggleMobileMenu(); this.switchCursorHoverEffect();} }>
          About
        </button>
        <button className="nav-button" title="Contact" onClick={ (e) => {this.handleViewChange(e); this.toggleMobileMenu(); this.switchCursorHoverEffect();} }>
          Contact
        </button>
      </Fragment>;
    }
    else {
      navLinkTree = 
      <Fragment>
        <button className="nav-button" title="Projects" onClick={this.handleViewChange}>
          Projects
        </button>
        <button className="nav-button" title="About" onClick={this.handleViewChange}>
          About
        </button>
        <button className="nav-button" title="Contact" onClick={this.handleViewChange}>
          Contact
        </button>
      </Fragment>;
    }
    return navLinkTree;
  }

  render() {
    //will be declared to selected view
    let content;

    //get view attribute from state
    const view = this.state.view;

    //save current view to content variable
    switch(true) {
      case(view.Home):
        content = <Home homeState={this.handleHomeState}/>;
        break;
      case(view.Projects):
        content = <Projects/>;
        break;
      case(view.About):
        content = <About/>;
        break;
      case(view.Contact):
        content = <Contact/>;
        break;
      default:
        content = <Home/>;
        break;
    }
    //toggle div.app's onMouseMove event depending on the state's attribute "isMobile"
    return (
      <div className="app" onMouseMove={this.state.isMobile ? null : this.draw}>
        <svg id="cursor" ref={this.cursor} height="20" width="20">
          <circle cx="10" cy="10" r="10"></circle>
        </svg>
        {this.buildMobileNav()}
        <header>
          {this.buildNavigation()}
        </header>

        <div id="wrapper" ref={this.wrapperRef}>
          <canvas id="canvas" ref={this.canvasRef} width={this.state.canvas.width} height={this.state.canvas.height}></canvas>
          <div id="content" className="container">
          {content}
          </div>
        </div>

        <footer>
          <div className="footer-container">
            Marius Unger - 2022
          </div>
          <div className="lgbtq-bar">
            <div className="red">
            </div>
            <div className="orange">
            </div>
            <div className="yellow">
            </div>
            <div className="green">
            </div>
            <div className="blue">
            </div>
            <div className="purple">
            </div>
          </div>
        </footer>
      </div>
      );
  }
}

export default App;
